function getBrowserType() {
    // Opera 8.0+
    const isOpera = (!!window.opr && !!window.opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;

    // Firefox 1.0+
    const isFirefox = typeof InstallTrigger !== 'undefined';

    // Safari 3.0+ "[object HTMLElementConstructor]" 
    const isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof window['safari'] !== 'undefined' && window['safari'].pushNotification));

    // Internet Explorer 6-11
    const isIE = /*@cc_on!@*/false || !!document.documentMode;

    // Edge 20+
    const isEdge = !isIE && !!window.StyleMedia;

    // Chrome 1 - 71
    const isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

    // Blink engine detection
    // const isBlink = (isChrome || isOpera) && !!window.CSS;
    return { isOpera, isFirefox, isSafari, isIE, isEdge, isChrome };
}

const pagesWithoutZoom = ['/', '/amplify', '/compass', '/bridge', '/for_banks', '/for_merchants', '/contact-us', '/about-us'];

const updateWindowZoom = () => {
    if (getBrowserType()?.browserType?.isFirefox) return;

    if (
      pagesWithoutZoom.includes(window.location.pathname)
    ) {
      document.body.style.zoom = '100%';
      return;
    }

    let hasChatBot = document.getElementById("tidio-chat-iframe");
    let chatBody = null;
    if (hasChatBot) {
      chatBody = document.getElementById("tidio-chat-iframe").contentWindow.document.getElementsByClassName("awesome-iframe")[0];
    }

    // no zoom for mobile
    if (window.innerWidth < 900) {
      if (window.location.pathname.includes('/amplify') && !window.location.pathname !== '/product/amplify') {
        if (document.getElementById('merchant-not-supported')) {
          document.getElementById('merchant-not-supported').style.visibility = 'visible';
        }
      } else {
        document.body.style.zoom = "100%";
        if (hasChatBot) {
          chatBody.style.zoom = '100%';
        }
      }
      return;
    } else if (window.location.pathname.includes('/amplify') && document.getElementById('merchant-not-supported')) {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent)) {
        document.getElementById('merchant-not-supported').style.visibility = 'visible';
      } else {
        document.getElementById('merchant-not-supported').style.visibility = 'hidden';
      }
    }

    // set zoom for desktop
    const widthRatio = window.innerWidth / 1920;
    let zoomLevel = 100;
    zoomLevel = 100 * widthRatio
    // zoomLevel = zoomLevel > 100 ? 100 : zoomLevel;
    document.body.style.zoom = zoomLevel.toString() + "%";

    // chatbot zoom for desktop
    if (hasChatBot) {
        chatBody.style.zoom = zoomLevel.toString() + '%';
    }
};

  export default updateWindowZoom;
